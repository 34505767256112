import classNames from 'classnames'
import { CSSProperties, PropsWithChildren } from 'react'

export const H1Bold = ({
  children,
  className,
  style,
}: PropsWithChildren<{ className?: string; style?: CSSProperties }>): JSX.Element => {
  return (
    <h1
      className={classNames('font-bold', className)}
      style={{ fontSize: 65, fontFamily: 'Roboto Condensed, Roboto, sans-serif', ...style }}
    >
      {children}
    </h1>
  )
}

export const H2Bold = ({
  children,
  className,
  style,
}: PropsWithChildren<{ className?: string; style?: CSSProperties }>): JSX.Element => {
  return (
    <h3
      className={classNames('font-bold', className)}
      style={{ fontSize: 36, fontFamily: 'Roboto Condensed, Roboto, sans-serif', ...style }}
    >
      {children}
    </h3>
  )
}

export const H3Regular = ({
  children,
  className,
  style,
}: PropsWithChildren<{ className?: string; style?: CSSProperties }>): JSX.Element => {
  return (
    <h3
      className={classNames('font-normal', className)}
      style={{ fontSize: 28, fontFamily: 'Roboto Condensed, Roboto, sans-serif', ...style }}
    >
      {children}
    </h3>
  )
}

export const H4Bold = ({
  children,
  className,
  style,
}: PropsWithChildren<{ className?: string; style?: CSSProperties }>): JSX.Element => {
  return (
    <h4
      className={classNames('font-bold', className)}
      style={{ fontSize: 20, fontFamily: 'Roboto Condensed, Roboto, sans-serif', ...style }}
    >
      {children}
    </h4>
  )
}

export const H5Regular = ({
  children,
  className,
  style,
}: PropsWithChildren<{ className?: string; style?: CSSProperties }>): JSX.Element => {
  return (
    <h4
      className={classNames('font-normal', className)}
      style={{ fontSize: 20, fontFamily: 'Roboto Condensed, Roboto, sans-serif', ...style }}
    >
      {children}
    </h4>
  )
}

export const TextBodyRegular = ({
  children,
  className,
  style,
}: PropsWithChildren<{ className?: string; style?: CSSProperties }>): JSX.Element => {
  return (
    <span
      className={classNames('font-normal', className)}
      style={{ fontSize: 16, fontFamily: 'Roboto Condensed, Roboto, sans-serif', ...style }}
    >
      {children}
    </span>
  )
}

export const TextButton = ({
  children,
  className,
  style,
}: PropsWithChildren<{ className?: string; style?: CSSProperties }>): JSX.Element => {
  return (
    <span
      className={classNames('font-bold', className)}
      style={{ fontSize: 18, fontFamily: 'Roboto Condensed, Roboto, sans-serif', ...style }}
    >
      {children}
    </span>
  )
}

export const TextBodyBold = ({
  children,
  className,
  style,
}: PropsWithChildren<{ className?: string; style?: CSSProperties }>): JSX.Element => {
  return (
    <span
      className={classNames('font-bold', className)}
      style={{ fontSize: 16, fontFamily: 'Roboto Condensed, Roboto, sans-serif', ...style }}
    >
      {children}
    </span>
  )
}

export const TextBodySmallRegular = ({
  children,
  className,
  style,
}: PropsWithChildren<{ className?: string; style?: CSSProperties }>): JSX.Element => {
  return (
    <span
      className={classNames('font-normal', className)}
      style={{ fontSize: 14, fontFamily: 'Roboto Condensed, Roboto, sans-serif', ...style }}
    >
      {children}
    </span>
  )
}

export const TextBodySmallBold = ({
  children,
  className,
  style,
}: PropsWithChildren<{ className?: string; style?: CSSProperties }>): JSX.Element => {
  return (
    <span
      className={classNames('font-bold', className)}
      style={{ fontSize: 14, fontFamily: 'Roboto Condensed, Roboto, sans-serif', ...style }}
    >
      {children}
    </span>
  )
}
