import { isObject } from './is-object'

export const customErrorToTrack = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  customError: any,
  status: number
): Record<string, string | number> => {
  // Is not managed customError
  if (
    !customError ||
    !isObject(customError) ||
    !Object.prototype.hasOwnProperty.call(customError, 'entity')
  ) {
    return {
      'Status code': status,
      'Error message': customError,
    }
  }

  return {
    'Status code': status,
    'Error message': customError.message,
    'Error code': customError.code,
    Entity: customError.entity,
    Context: customError.context,
    'Correlation ID': customError.correlationId,
  }
}
