import {
  ButtonProps,
  getButtonClassName,
  SIZE,
} from '@blockchain-traceability-sl/tailwind-components'
import classNames from 'classnames'
import {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  PropsWithChildren,
  ReactNode,
} from 'react'
import { H2Bold, H4Bold, H5Regular, TextBodyRegular } from './Typography'

export const ButtonMain = ({
  children,
  className,
  ...props
}: PropsWithChildren<
  DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
>): JSX.Element => {
  return (
    <button
      {...props}
      className={classNames('flex flex-row justify-center items-center text-white', className)}
      style={{
        padding: '9px 29px',
        background: 'linear-gradient(71.26deg, #DF992F 27.74%, #CF441C 96.11%)',
        borderRadius: 8,
        ...props.style,
      }}
    >
      {children}
    </button>
  )
}

export const ButtonMainLink = ({
  children,
  className,
  ...props
}: PropsWithChildren<
  DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>
>): JSX.Element => {
  return (
    <a
      {...props}
      className={classNames('flex flex-row justify-center items-center text-white', className)}
      style={{
        padding: '9px 29px',
        background: 'linear-gradient(71.26deg, #DF992F 27.74%, #CF441C 96.11%)',
        borderRadius: 8,
      }}
    >
      {children}
    </a>
  )
}

export const ButtonSecondary = ({
  children,
  className,
  backgroundColor,
  ...props
}: PropsWithChildren<
  DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
    backgroundColor: string
  }
>): JSX.Element => {
  return (
    <button
      {...props}
      className={classNames('flex flex-row justify-center items-center text-white', className)}
      style={{
        borderRadius: 8,
        padding: '6px 29px',
        color: 'black',
        border: 'solid 3px transparent',
        backgroundImage: 'linear-gradient(71.26deg, #DF992F 27.74%, #CF441C 96.11%)',
        backgroundOrigin: 'border-box',
        backgroundClip: 'border-box',
        boxShadow: `2px 1000px 1px ${backgroundColor} inset`,
      }}
    >
      {children}
    </button>
  )
}

export const ButtonSecondaryLink = ({
  children,
  className,
  backgroundColor,
  ...props
}: PropsWithChildren<
  DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> & {
    backgroundColor: string
  }
>): JSX.Element => {
  return (
    <a
      {...props}
      className={classNames('flex flex-row justify-center items-center text-white', className)}
      style={{
        borderRadius: 8,
        padding: '6px 29px',
        color: 'black',
        border: 'solid 3px transparent',
        backgroundImage: 'linear-gradient(71.26deg, #DF992F 27.74%, #CF441C 96.11%)',
        backgroundOrigin: 'border-box',
        backgroundClip: 'border-box',
        boxShadow: `2px 1000px 1px ${backgroundColor} inset`,
      }}
    >
      {children}
    </a>
  )
}

export const ButtonAction = ({
  children,
  size = SIZE.MEDIUM,
  className,
  ...props
}: ButtonProps): JSX.Element => {
  return (
    <button
      type='button'
      className={classNames(
        getButtonClassName({ color: 'warning', size, disabled: props.disabled }),
        className,
        'focus:ring-purple-900'
      )}
      {...props}
      style={{
        backgroundImage: 'linear-gradient(90deg, rgba(22, 4, 49, 1), rgba(9, 2, 20, 1))',
      }}
    >
      {children}
    </button>
  )
}

export const ButtonSpecialLink = ({
  children,
  className,
  ...props
}: PropsWithChildren<
  DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>
>): JSX.Element => {
  return (
    <a
      {...props}
      className={classNames('flex flex-row justify-center items-center text-white', className)}
      style={{
        padding: '9px 29px',
        background: 'linear-gradient(71.26deg, #1FCFBF 27.74%, #0CB3A3 96.11%)',
        borderRadius: 8,
        ...props.style,
      }}
    >
      {children}
    </a>
  )
}

export const ButtonWhiteLink = ({
  children,
  className,
  ...props
}: PropsWithChildren<
  DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>
>): JSX.Element => {
  return (
    <a
      {...props}
      className={classNames(
        'flex flex-row justify-center items-center text-white border border-white box-border',
        className
      )}
      style={{
        padding: '9px 29px',
        borderRadius: 8,
        ...props.style,
      }}
    >
      {children}
    </a>
  )
}

export const FeatureCard = ({
  name,
  description,
  imageSrc,
}: {
  name: string
  description: string
  imageSrc: string
}): JSX.Element => {
  return (
    <div
      className='relative'
      style={{
        borderRadius: 30,
      }}
    >
      <div
        className='absolute h-full w-full'
        style={{
          backgroundColor: 'rgba(16, 217, 198, 0.14)',
          opacity: 0.4,
          boxShadow:
            'inset 0px 4px 8px rgba(255, 255, 255, 0.25), inset 2px 2px 12px rgba(255, 255, 255, 0.45)',
          borderRadius: 30,
        }}
      />
      <div className='flex-col justify-center content-center px-8 py-10 space-y-8'>
        <div className='relative m-auto' style={{ width: 97, height: 97 }}>
          <div
            className='absolute w-full h-full'
            style={{
              background: 'rgba(16, 217, 198, 0.6)',
              filter: 'blur(40px)',
            }}
          />
          <img
            src={imageSrc}
            className='m-auto'
            style={{
              color: 'rgba(255, 255, 255, 0.2)',
              padding: 3,
              // boxShadow:
              //   'inset 0px 4px 8px rgba(255, 255, 255, 0.25), inset 2px 2px 12px rgba(255, 255, 255, 0.45)',
            }}
            alt='hold'
          />
        </div>
        <H4Bold className='uppercase text-white'>{name}</H4Bold>
        <TextBodyRegular className='text-white'>{description}</TextBodyRegular>
      </div>
    </div>
  )
}

export const SaleCard = ({
  title,
  subTitle,
  startDate,
  imageSrc,
}: {
  title: ReactNode
  subTitle?: ReactNode
  startDate: ReactNode
  imageSrc: string
}): JSX.Element => {
  return (
    <div
      className='relative'
      style={{
        borderRadius: 30,
      }}
    >
      <div
        className='absolute h-full w-full'
        style={{
          backgroundColor: 'rgba(16, 217, 198, 0.14)',
          opacity: 0.4,
          boxShadow:
            'inset 0px 4px 8px rgba(255, 255, 255, 0.25), inset 2px 2px 12px rgba(255, 255, 255, 0.45)',
          borderRadius: 30,
        }}
      />
      <div className='flex flex-col h-full justify-between content-between px-8 py-10 space-y-8'>
        <div>
          <H2Bold className='text-white'>{title}</H2Bold>
          <H4Bold className='text-white'>{subTitle}</H4Bold>
          <H5Regular className='text-white'>{startDate}</H5Regular>
        </div>
        <div>
          <img src={imageSrc} className='mx-auto' alt='sale' />
        </div>
      </div>
    </div>
  )
}
