import { useEffect, useRef } from 'react'
import { useAuth } from '../auth/use-auth'

import { io, Socket } from 'socket.io-client'
import { Config } from '@/config'
import { useNotify } from '@/hooks/use-notify'
import i18n from '@/i18n'
import { UserKYCStatus, UserPaymentStatus, UserTransaction } from '../auth/entity'

enum EVENTS {
  TRANSACTION = 'transaction',
  KYC_STATUS = 'kycStatus',
}

export const useSocket = (): void => {
  const socketRef = useRef<Socket>()
  const auth = useAuth()
  const notify = useNotify()

  useEffect(() => {
    if (auth && auth.isAuthenticated && auth.user) {
      // Creates a WebSocket connection
      socketRef.current = io(Config.API_URL, {
        auth: { token: `Bearer ${auth.token}` },
        transports: ['websocket'],
      })

      // Listens for incoming messages
      socketRef.current.on(
        EVENTS.TRANSACTION,
        (payload: { paymentStatus: UserPaymentStatus; transaction?: UserTransaction }) => {
          auth.changePaymentStatus(payload.paymentStatus)

          switch (payload.paymentStatus) {
            case UserPaymentStatus.SUCCESS:
              auth.removePaymentDelay()
              payload.transaction && auth.addStateTransaction(payload.transaction)
              notify.success({
                title: i18n.t('nsNotifications:success.payment.title'),
                description: i18n.t('nsNotifications:success.payment.description'),
              })
              break
            case UserPaymentStatus.FAILED:
              auth.removePaymentDelay()
              notify.error({
                title: i18n.t('nsNotifications:error.payment.title'),
                description: i18n.t('nsNotifications:error.payment.description'),
                actionText: i18n.t('nsNotifications:error.payment.action'),
              })
              break
          }
        }
      )

      socketRef.current.on(EVENTS.KYC_STATUS, ({ status }: { status: UserKYCStatus }) => {
        auth.changeKYCStatus(status)
        if (status === UserKYCStatus.APPROVED) {
          notify.success({
            title: i18n.t('nsNotifications:success.idenfy.title'),
            description: i18n.t('nsNotifications:success.idenfy.description'),
          })
        } else if (status !== UserKYCStatus.PENDING) {
          notify.error({
            title: i18n.t('nsNotifications:error.idenfy.title'),
            description: i18n.t('nsNotifications:error.idenfy.description'),
            actionText: i18n.t('nsNotifications:error.idenfy.action'),
          })
        }
      })

      // Logout user on get a connection error in websocket connexion
      socketRef.current.on('connect_error', () => {
        auth.signout()
      })
    }

    // Destroys the socket reference
    // when the connection is closed
    return () => {
      socketRef.current?.disconnect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.isAuthenticated])
}
